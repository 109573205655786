import { render, staticRenderFns } from "./CheckoutLegal.html?vue&type=template&id=0b879bb2&scoped=true&"
import script from "./CheckoutLegal.ts?vue&type=script&lang=ts&"
export * from "./CheckoutLegal.ts?vue&type=script&lang=ts&"
import style0 from "./CheckoutLegal.scss?vue&type=style&index=0&id=0b879bb2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b879bb2",
  null
  
)

export default component.exports